import React, { useState, useContext, useMemo } from "react";
//import PropTypes from "prop-types";
import Helmet from "react-helmet";
import useSWR from "swr";
import { postRequest } from "../Helpers/fetcher.js";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserContext from "../UserContext.js";
import Style from "./PageMatchInput.module.scss";
import { Button } from "react-bootstrap";
import SimpleTable from "../Components/SimpleTable.js";
import {
  renderDateMultiline,
  renderRowValue,
} from "../Components/SimpleTableRender/basic.js";

const PageMatchInputSelection = () => {
  const { t } = useTranslation();
  const { token } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const [filters, setFilters] = useState([]);

  const { data, isValidating, mutate } = useSWR("/getAllMatches?stages=1,2");
  const { data: dataEnumTournaments } = useSWR("/getEnum/tournamentTemplate");
  const { data: dataEnumSports } = useSWR("/getEnum/sports");
  const matchList = data?.response?.matches;

  const enumSports = dataEnumSports?.response?.enums;
  const enumTournaments = useMemo(() => {
    const sportId = filters.reduce(
      (ret, item) => (item.col === "sportId" ? parseInt(item.value) : ret),
      false
    );
    if (sportId) {
      return dataEnumTournaments?.response?.enums?.filter(
        (item) => item.sportId === sportId
      );
    }

    return dataEnumTournaments?.response?.enums;
  }, [filters, dataEnumTournaments]);

  const handleSelectionTransfer = () => {
    //selectedIds

    toast.promise(
      new Promise((resolve, reject) => {
        postRequest("/transferMatches", token, {
          ids: selectedIds,
        })
          .then((resp) => {
            if (resp.error_code !== 200) {
              toast.error(t(`error-${resp.error}`));
              reject();
              return false;
            }

            navigate("/match-input/edit");
            resolve();
          })
          .catch((err) => {
            console.error({ err });
            reject();
          });
      }),
      {
        pending: t("working"),
        success: t("match-input-transfer-ok"),
        error: t("error-unhandled"),
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("match-input-page-title")}</title>
      </Helmet>

      <div className={Style.wrapper}>
        <div className={Style.matchTable}>
          <SimpleTable
            data={matchList}
            isLoading={isValidating}
            onRefresh={mutate}
            idx="id"
            allowSelection
            onSelectionUpdate={setSelectedIds}
            onFiltersChange={setFilters}
            headerComponent={() => (
              <Button
                variant="primary"
                size="sm"
                onClick={handleSelectionTransfer}
                className={Style.stickyButton}
              >
                <i className="fa-thin fa-arrow-right-arrow-left me-2" />
                {t("match-input-transfer-to-edit")}
              </Button>
            )}
            cols={[
              {
                id: "timeStart",
                title: t("date"),
                render: renderDateMultiline(["LL", "LT"]),
              },
              {
                id: "participantNames",
                title: t("match-input-table-participants"),
                filterText: true,
              },
              {
                id: "sportId",
                title: t("match-input-table-sport"),
                render: renderRowValue("sportName"),
                filterEnum: enumSports,
              },
              {
                id: "tournamentTemplateId",
                title: t("match-input-table-tournament"),
                render: renderRowValue("tournamentTemplateName"),
                filterEnum: enumTournaments,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

PageMatchInputSelection.propTypes = {};

export default PageMatchInputSelection;
