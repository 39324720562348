import React from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";
import useSWR from "swr";
import { swrFetcherRawText } from "../Helpers/fetcher.js";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import styles from "./RenderMd.module.scss";

import mdEnCommenterHelp from "../localize/md/commenter-help.en.md";

const selectMdContent = (lang, file) => {
  if (lang === "cs") {
    // eslint-disable-next-line default-case
    switch (file) {
      case "commenter-help":
        return mdEnCommenterHelp;
    }
  }

  if (lang === "en") {
    // eslint-disable-next-line default-case
    switch (file) {
      case "commenter-help":
        return mdEnCommenterHelp;
    }
  }

  return mdEnCommenterHelp; // fallback
};

const RenderMD = ({ file }) => {
  const { i18n } = useTranslation();
  const currentLang = isObject(i18n) ? i18n.language : null;
  // const fallbackLang = isObject(i18n) ? i18n.options?.fallbackLng[0] : null;

  const contentUrl = selectMdContent(currentLang, file);
  const { data } = useSWR(contentUrl, (url) =>
    swrFetcherRawText(url).then((resp) => resp)
  );

  if (!data) return "...";

  return <ReactMarkdown className={styles.content}>{data}</ReactMarkdown>;
};

RenderMD.propTypes = {
  file: PropTypes.string,
};

export default RenderMD;
