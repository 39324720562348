import React from "react";
import { t } from "i18next";
import { Button } from "react-bootstrap";

export const renderOnAirStatus = (
  handlePlay,
  isOnline = true,
  colWithStream = "streamUrl"
) => {
  const cb = (value, row, idxValue) => {
    const isOnair = value ? true : false;
    const keyId = `onAirStatus-${idxValue}-${isOnair}`;
    const renderVariant = isOnline
      ? isOnair
        ? "danger"
        : "warning"
      : "outline-info";

    const handleClick = (e) => {
      if (handlePlay) {
        handlePlay(row[colWithStream], row, idxValue);
      }
    };

    return (
      <Button
        key={keyId}
        variant={renderVariant}
        size="sm"
        onClick={handleClick}
        //disabled={!isOnair || !isOnline}
        title={row?.streamUrl}
      >
        {t(isOnline ? (isOnair ? "onair" : "offair") : "offair-offline")}
      </Button>
    );
  };
  return cb;
};
