import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import ReactTooltip from "react-tooltip";

const LanguageIcon = ({ langId, withLabel }) => {
  const { t } = useTranslation();
  const { data: dataEnumLanguages } = useSWR("/getEnum/languages");
  const languages = dataEnumLanguages?.response?.enums;

  const emptyLangObj = { langIcon: "_none.svg", langTitle: "unknown" };
  const { langIcon, langTitle } = languages
    ? languages.reduce(
        (ret, lang) =>
          lang.id === langId
            ? { langIcon: lang.icon, langTitle: lang.title }
            : ret,
        emptyLangObj
      )
    : emptyLangObj;

  return (
    <>
      <img
        src={require(`../assets/flags/${langIcon}`)}
        alt={t(langTitle)}
        data-tip={t(langTitle)}
        style={{ height: "1em", marginRight: ".5em", paddingBottom: "0.2em" }}
      />
      {withLabel && <span>{t(langTitle)}</span>}
      <ReactTooltip effect="solid" />
    </>
  );
};

LanguageIcon.propTypes = {
  langId: PropTypes.string.isRequired,
  withLabel: PropTypes.bool,
};
LanguageIcon.defaultProps = {
  withLabel: false,
};

export default LanguageIcon;
