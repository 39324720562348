import React, { useContext } from "react";
// import PropTypes from "prop-types";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";
//import Style from "./PageXXX.module.scss";
import SimpleTable from "../Components/SimpleTable.js";
import {
  renderDate,
  renderDateMultiline,
  renderRowValue,
} from "../Components/SimpleTableRender/basic.js";
import { renderLang } from "../Components/SimpleTableRender/lang.js";
// import {} from "react-bootstrap";

const PageMyMatches = () => {
  const { t } = useTranslation();
  const { userinfo } = useContext(UserContext);

  const { data, isValidating, mutate } = useSWR(`/getMyMatches/${userinfo.id}`);
  const matchList = data?.response?.matches;

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("page-myMatches-title")}</title>
      </Helmet>
      <div className={""}>
        <h1>{t("page-myMatches-title")}</h1>
        <SimpleTable
          data={matchList}
          isLoading={isValidating}
          onRefresh={mutate}
          idx="id"
          cols={[
            {
              id: "timeStart",
              title: "",
              render: renderDate("relative"),
            },
            {
              id: "timeStart",
              title: t("date"),
              render: renderDateMultiline(["LL", "LT"]),
            },
            {
              id: "participantNames",
              title: t("match-input-table-participants"),
            },
            {
              id: "sportId",
              title: t("match-input-table-sport"),
              render: renderRowValue("sportName"),
            },
            {
              id: "tournamentTemplateId",
              title: t("match-input-table-tournament"),
              render: renderRowValue("tournamentTemplateName"),
            },
            {
              id: "language",
              title: t("match-input-table-lang"),
              render: renderLang(),
            },
          ]}
        />
      </div>
    </LayoutBasic>
  );
};

PageMyMatches.propTypes = {};

export default PageMyMatches;
