import React, { useEffect } from "react";
//import PropTypes from "prop-types";
import { useParams, useNavigate, Link } from "react-router-dom";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
//import UserContext from "../UserContext.js";
import Style from "./PageMatchInput.module.scss";
import { Nav } from "react-bootstrap";
import PageMatchInputSelection from "./PageMatchInputSelection.js";
import PageMatchInputEditation from "./PageMatchInputEditation.js";

const PageMatchInput = () => {
  const { t } = useTranslation();
  const { page } = useParams();
  const navigate = useNavigate();
  //const {  } = useContext(UserContext);

  useEffect(() => {
    if (page !== "select" && page !== "edit") {
      navigate("/match-input/select", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("match-input-page-title")}</title>
      </Helmet>

      <div className={Style.wrapper}>
        <h1>{t("match-input-page-title")}</h1>

        <Nav variant="tabs" className="mb-5">
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/match-input/select"
              active={page === "select"}
            >
              <i className="fa-thin fa-book-sparkles me-2" />
              {t("match-input-page-tab-selection")}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to="/match-input/edit" active={page === "edit"}>
              <i className="fa-thin fa-shirt me-2" />
              {t("match-input-page-tab-editation")}
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <section>
          {page === "select" && <PageMatchInputSelection />}
          {page === "edit" && <PageMatchInputEditation />}
        </section>
      </div>
    </LayoutBasic>
  );
};

PageMatchInput.propTypes = {};

export default PageMatchInput;
