import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import useOnlineStatus from "../hooks/useOnlineStatus.js";
import { Image, Button } from "react-bootstrap";
import UserContext from "../UserContext.js";
import Style from "./LayoutBasic.module.scss";
import routes from "../configs/routes.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import appPackage from "../../package.json";
import { t } from "i18next";

const LayoutBasic = ({ children }) => {
  //const { t } = useTranslation();
  const { userLogout, isLoggedIn, userinfo, menuCollapsed, setMenuCollapsed } =
    useContext(UserContext);
  const navigate = useNavigate();
  const isOnline = useOnlineStatus();

  const { data: dataApiVer } = useSWR("/getApiVer");
  const apiVer = dataApiVer?.response;

  const userLogin = () => {
    navigate("/login");
  };

  const handleMenuCollapse = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  return (
    <>
      <div className={Style.wrapper}>
        <div className={`${Style.nav} ${menuCollapsed ? Style.collapsed : ""}`}>
          <nav>
            <div className={Style.logo}>
              <Link to="/">
                {!menuCollapsed && (
                  <>
                    Audio<span>Live</span>&nbsp;
                  </>
                )}
                <i className="fa-regular fa-podcast" />
              </Link>
            </div>
            <MenuItems />
          </nav>

          <div>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={handleMenuCollapse}
              className={Style.collapseBtn}
            >
              <i
                className={`fa-regular fa-angles-${
                  menuCollapsed ? "right" : "left"
                }`}
              />
            </Button>
          </div>
        </div>
        <div className={Style.contentWrap} style={{ paddingLeft: 0 }}>
          <section className={Style.header}>
            <div></div>

            <div className={Style.user}>
              {isLoggedIn && (
                <div className={Style.item}>
                  <Link to="/">
                    <Button size="sm" variant="outline-light">
                      <Image fluid roundedCircle src={userinfo.avatar} />
                      {userinfo.name}
                    </Button>
                  </Link>
                </div>
              )}

              {isLoggedIn && (
                <div className={Style.item} onClick={userLogout}>
                  <i className="fa fa-thin fa-right-from-bracket" />
                </div>
              )}

              {!isLoggedIn && (
                <div className={Style.item} onClick={userLogin}>
                  <i className="fa-thin fa-lock" />
                </div>
              )}
            </div>
          </section>

          <section className={Style.content}>{children}</section>
        </div>
      </div>

      <section className={Style.footer}>
        <div className={Style.online}>
          {isOnline ? t("online") : t("offline")}
        </div>
        <div className={Style.version}>
          API v{apiVer} | UI v{appPackage.version}
        </div>
      </section>
    </>
  );
};

LayoutBasic.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
LayoutBasic.defaultProps = {};

const MenuItems = () => {
  const { t } = useTranslation();
  const currentLocation = useLocation();
  const { userinfo } = useContext(UserContext);

  const displayItems = useMemo(
    () =>
      routes
        .filter((item) => item.menuTitle)
        .sort((a, b) =>
          a.menuOrder === b.menuOrder ? 0 : a.menuOrder > b.menuOrder ? 1 : -1
        ),
    []
  );

  // className={Style.active}
  return (
    <ul>
      {displayItems.map((route) => {
        const userScopes = userinfo?.scope || [];
        const reqScope = route?.loginScope;

        if (
          (!reqScope ||
            !userScopes.reduce(
              (ret, userScope) => (reqScope.includes(userScope) ? true : ret),
              false
            )) &&
          reqScope !== "*"
        ) {
          return null;
        }

        const isActive = String(currentLocation?.pathname).startsWith(
          route.path
        );

        return (
          <li key={`menuItem-${route.path}`}>
            <Link to={route.path} className={`${isActive ? Style.active : ""}`}>
              <i className={`fa-thin fa-${route.menuIcon} me-2`} />
              <span>{t(`menu-${route.menuTitle}`)}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default LayoutBasic;
