import React from "react";
// import { t } from "i18next";
import LanguageIcon from "../LanguageIcon.js";
import LineClamp from "../LineClamp.js";

export const renderLang = () => {
  const cb = (value, _row, _idxValue) => {
    if (value.includes(",")) {
      const langs = value.split(",");

      return (
        <LineClamp lines={3}>
          {langs.map((lang, idx) => (
            <div key={lang}>
              <LanguageIcon langId={lang} withLabel />
            </div>
          ))}
        </LineClamp>
      );
    }

    return <LanguageIcon langId={value} withLabel />;
  };
  return cb;
};
