import React, { useState } from "react";
import PropTypes from "prop-types";
import Styles from "./LineClamp.module.scss";
const LineClamp = ({ children }) => {
  const [isExpanded, expand] = useState(false);
  const handleClick = () => {
    expand(!isExpanded);
  };
  return (
    <div
      className={`${Styles.clamp} ${isExpanded ? "" : Styles.collapsed}`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

LineClamp.propTypes = {
  children: PropTypes.node,
};

export default LineClamp;
