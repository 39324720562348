import React, { useContext } from "react";
//import PropTypes from "prop-types";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useOnlineStatus from "../hooks/useOnlineStatus.js";
import useSWR from "swr";
import { postRequest } from "../Helpers/fetcher.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserContext from "../UserContext.js";
import { ButtonGroup, Button } from "react-bootstrap";
import Style from "./PageReadyAndLive.module.scss";
import SimpleTable from "../Components/SimpleTable.js";
import { openHlsPlayer, hlsPlayerLink } from "../Helpers/hlsPlayer.js";
import {
  renderDateMultiline,
  renderMoreValueButton,
  renderTranslate,
  renderRowValue,
} from "../Components/SimpleTableRender/basic.js";
import { renderLang } from "../Components/SimpleTableRender/lang.js";
import { renderOnAirStatus } from "../Components/SimpleTableRender/onAirStatus.js";

const PageReadyAndLive = () => {
  const { t } = useTranslation();
  const isOnline = useOnlineStatus();
  const { token } = useContext(UserContext);
  const navigate = useNavigate();

  const handlePlay = (url, row, _idxValue) => {
    openHlsPlayer(url, row.participantNames, row.tournamentTemplateName, "hls");
  };

  const { data, isValidating, mutate } = useSWR("/getActiveStreams", null, {
    refreshInterval: 20000,
  });

  //const { data: dataEnumTournaments } = useSWR("/getEnum/tournamentTemplate");
  const { data: dataEnumSports } = useSWR("/getEnum/sports");
  const { data: dataEnumTournaments } = useSWR("/getEnum/tournamentTemplate");
  const { data: dataEnumLangs } = useSWR("/getEnum/languages");

  const enumSports = dataEnumSports?.response?.enums;
  const enumTournaments = dataEnumTournaments?.response?.enums;
  const enumLangs = dataEnumLangs?.response?.enums;
  const streamsList = data?.response?.streams;

  const streamsListLive = streamsList?.filter(
    (item) =>
      parseInt(item.stageType) === 3 ||
      parseInt(item.stageType) === 2 ||
      (parseInt(item.stageType) === 1 &&
        dayjs(item.timeStart).isBefore(dayjs().add(10, "minutes")) &&
        dayjs(item.timeStart).isAfter(dayjs().subtract(15, "minutes")))
  );

  const streamsListReady = streamsList?.filter(
    (item) => parseInt(item.stageType) === 1
  );

  const handleStreamUrlTransform = (value, row, __idxValue) => {
    return hlsPlayerLink(
      value,
      row.participantNames,
      row.tournamentTemplateName,
      "hls"
    );
  };

  const renderMatchEditButton = () => {
    const cb = (val, row, idxValue) => {
      const handleEdit = () => {
        toast.promise(
          new Promise((resolve, reject) => {
            postRequest("/transferMatches", token, {
              ids: [row.matchId],
            })
              .then((resp) => {
                if (resp.error_code !== 200) {
                  toast.error(t(`error-${resp.error}`));
                  reject();
                  return false;
                }

                navigate(`/match-input/edit/${row.matchId}`);
                resolve();
              })
              .catch((err) => {
                console.error({ err });
                reject();
              });
          }),
          {
            pending: t("working"),
            success: t("match-input-transfer-ok"),
            error: t("error-unhandled"),
          }
        );
      };

      return (
        <ButtonGroup aria-label="Basic example">
          <Button size="sm" variant="outline-dark" onClick={handleEdit}>
            <i className={`fa-thin fa-edit`} />
          </Button>
        </ButtonGroup>
      );
    };
    return cb;
  };

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("ready-and-live-page-title")}</title>
      </Helmet>
      <div className={Style.wrapper}>
        <h1>{t("ready-and-live-page-title")}</h1>

        <div className={Style.matchTable}>
          <SimpleTable
            data={streamsListLive}
            isLoading={isValidating}
            onRefresh={mutate}
            idx="id"
            pageSize={150}
            cols={[
              {
                id: "isCommenterOnline",
                title: t("ready-and-live-onair"),
                render: renderOnAirStatus(handlePlay, isOnline, "streamUrl"),
              },
              {
                id: "stateId",
                title: t("ready-and-live-stream-state"),
                render: renderTranslate("match-state-", "stateName"),
              },
              {
                id: "timeStart",
                title: t("date"),
                render: renderDateMultiline(["LL", "LT"]),
              },
              {
                id: "participantNames",
                title: t("match-input-table-participants"),
                filterText: true,
              },
              {
                id: "sportId",
                title: t("match-input-table-sport"),
                render: renderRowValue("sportName"),
                filterEnum: enumSports,
              },
              {
                id: "tournamentTemplateId",
                title: t("match-input-table-tournament"),
                render: renderRowValue("tournamentTemplateName"),
                filterEnum: enumTournaments,
              },
              {
                id: "langId",
                title: t("ready-and-live-stream-lang"),
                render: renderLang(),
                filterEnum: enumLangs,
              },
              {
                id: "fullname",
                title: t("ready-and-live-stream-commenter"),
              },
              {
                id: "streamUrl",
                title: t("ready-and-live-stream-url"),
                render: renderMoreValueButton({
                  copy: true,
                  transform: handleStreamUrlTransform,
                }),
              },

              {
                id: "matchId",
                title: "",
                render: renderMatchEditButton(),
              },
            ]}
          />
        </div>

        <h2 className="title h3 mt-5">{t("ready-and-live-page-upcoming")}</h2>

        <div className={Style.matchTable}>
          <SimpleTable
            data={streamsListReady}
            isLoading={isValidating}
            onRefresh={mutate}
            idx="id"
            cols={[
              {
                id: "isCommenterOnline",
                title: t("ready-and-live-test"),
                render: renderOnAirStatus(
                  handlePlay,
                  isOnline,
                  "commenterLink"
                ),
              },

              {
                id: "timeStart",
                title: t("date"),
                render: renderDateMultiline(["LL", "LT"]),
              },
              {
                id: "participantNames",
                title: t("match-input-table-participants"),
                filterText: true,
              },
              {
                id: "sportId",
                title: t("match-input-table-sport"),
                render: renderRowValue("sportName"),
                filterEnum: enumSports,
              },
              {
                id: "tournamentTemplateId",
                title: t("match-input-table-tournament"),
                render: renderRowValue("tournamentTemplateName"),
                filterEnum: enumTournaments,
              },
              {
                id: "langId",
                title: t("ready-and-live-stream-lang"),
                render: renderLang(),
                filterEnum: enumLangs,
              },
              {
                id: "fullname",
                title: t("ready-and-live-stream-commenter"),
              },
              {
                id: "streamUrl",
                title: t("ready-and-live-stream-url"),
                render: renderMoreValueButton({
                  copy: true,
                  transform: handleStreamUrlTransform,
                }),
              },
              {
                id: "matchId",
                title: "",
                render: renderMatchEditButton(),
              },
            ]}
          />
        </div>
      </div>
    </LayoutBasic>
  );
};

PageReadyAndLive.propTypes = {};

export default PageReadyAndLive;
