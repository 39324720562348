import React, { useContext, useState } from "react";
//import PropTypes from "prop-types";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../Helpers/fetcher.js";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import UserContext from "../UserContext.js";
import Style from "./PageAdminUsers.module.scss";
import { Button, Form, FloatingLabel, Modal } from "react-bootstrap";
import SimpleTable from "../Components/SimpleTable.js";
import {
  renderAvatar,
  renderBool,
  renderDate,
  renderTranslate,
  renderButton,
} from "../Components/SimpleTableRender/basic.js";
import FormLanguageSelect from "../Components/FormLanguageSelect.js";
import FormScopeSelect from "../Components/FormScopeSelect.js";
import { isArray } from "lodash";

const PageAdminUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFormValid, setEditFormValid] = useState(false);

  const [frmUserId, frmSetUserId] = useState(null);
  const [frmName, frmSetName] = useState("");
  const [frmEmail, frmSetEmail] = useState("");
  const [frmIsActive, frmSetIsActive] = useState("1");
  const [frmScope, frmSetScope] = useState([]);
  const [frmLanguages, frmSetLanguages] = useState([]);

  const { token, storeLoginData } = useContext(UserContext);
  const { data, isValidating, mutate } = useSWR("/adminUsers");
  const { data: dataEnumScopes } = useSWR("/getEnum/scopes");
  const userList = data?.response;
  const enumScopes = dataEnumScopes?.response?.enums;

  const resetFormState = () => {
    frmSetUserId(null);
    frmSetEmail("");
    frmSetName("");
    frmSetIsActive("1");
    frmSetScope([]);
    frmSetLanguages([]);
  };

  const populateFormState = (userId) => {
    const row = userList.filter((i) => i.id === userId);

    if (row.length === 1) {
      frmSetUserId(userId);
      frmSetEmail(row[0].email);
      frmSetName(row[0].fullname);
      frmSetIsActive(row[0].active);

      try {
        frmSetScope(JSON.parse(row[0].scope || "[]"));
      } catch {
        frmSetScope([row[0].scope]);
      }

      try {
        frmSetLanguages(JSON.parse(row[0].languages || "[]"));
      } catch {
        console.error("missformated-languages");
      }
    }
  };

  const openEditModal = (userId) => {
    if (userId) {
      populateFormState(userId);
    } else {
      resetFormState();
    }

    setEditFormValid(false);
    setShowEditModal(true);
  };

  const hideEditModal = () => {
    setShowEditModal(false);
    resetFormState();
  };

  const handleEditSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      setEditFormValid(true);
      return false;
    }

    toast.promise(
      new Promise((resolve, reject) => {
        postRequest("/adminUsers", token, {
          id: frmUserId,
          email: frmEmail,
          fullname: frmName,
          active: frmIsActive,
          scope: frmScope,
          languages: frmLanguages,
        })
          .then((resp) => {
            if (resp.error_code !== 200) {
              toast.error(t(`error-${resp.error}`));
              reject();
              return false;
            }

            setEditFormValid(false);
            hideEditModal();
            mutate()
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          })
          .catch((err) => {
            console.error({ err });
            reject();
          });
      }),
      {
        pending: t("working"),
        success: t("admin-users-saved"),
        error: t("error-unhandled"),
      }
    );
  };

  const handleUserSwitch = (id) => {
    toast.promise(
      new Promise((resolve, reject) => {
        postRequest("/login/switch-context", token, {
          userId: id,
          token: token,
        })
          .then((resp) => {
            if (resp.error_code !== 200) {
              reject();
              return false;
            }

            const { token, userinfo } = resp.response;
            storeLoginData(token, userinfo);
            navigate("/");
            toast.success(
              t(`${t("login-switch-context-done")} ${userinfo?.email}`)
            );
            resolve();
          })
          .catch((err) => {
            console.error({ err });
            reject();
          });
      }),
      {
        pending: t("login-switch-context"),
        success: null,
        error: t("error-unhandled"),
      }
    );
  };

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("admin-users-page-title")}</title>
      </Helmet>

      <Modal
        show={showEditModal}
        onHide={hideEditModal}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("user-admin-edit-item")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={editFormValid}
            onSubmit={handleEditSubmit}
          >
            <Form.Group className="mb-3">
              <FloatingLabel
                label={t("user-admin-table-email")}
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  required
                  value={frmEmail}
                  onChange={(e) => {
                    frmSetEmail(e.target.value);
                  }}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <FloatingLabel
                label={t("user-admin-table-name")}
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  required
                  value={frmName}
                  onChange={(e) => {
                    frmSetName(e.target.value);
                  }}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="switch"
                label={t("user-admin-table-is-active")}
                checked={frmIsActive === "1" ? true : false}
                onChange={(e) => frmSetIsActive(e.target.checked ? "1" : "0")}
              />
            </Form.Group>

            <hr />

            <Form.Group className="mb-3">
              <label className="h5">{t("user-admin-table-scope")}</label>
              <FormScopeSelect
                key={frmUserId}
                value={isArray(frmScope) ? frmScope : [frmScope]}
                onChange={(v) => frmSetScope(v)}
              />
            </Form.Group>

            <hr />

            <Form.Group className="mb-3">
              <label className="h5">{t("user-admin-table-language")}</label>
              <FormLanguageSelect
                key={frmUserId}
                value={frmLanguages}
                onChange={(v) => frmSetLanguages(v)}
              />
            </Form.Group>

            <Form.Group className="text-end">
              <Button variant="secondary" onClick={hideEditModal}>
                {t("cancel")}
              </Button>
              &nbsp;
              <Button variant="primary" type="submit">
                {t("save")}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      <div className={Style.wrapper}>
        <h1>{t("admin-users-page-title")}</h1>

        <div className={Style.userTable}>
          <SimpleTable
            data={userList}
            isLoading={isValidating}
            idx="id"
            headerComponent={() => (
              <Button onClick={openEditModal}>
                <i className="fa-thin fa-add me-2" />
                {t("new-user")}
              </Button>
            )}
            cols={[
              { id: "avatar", title: "", render: renderAvatar() },
              {
                id: "fullname",
                title: t("user-admin-table-name"),
                filterText: true,
              },
              {
                id: "email",
                title: t("user-admin-table-email"),
                filterText: true,
              },
              {
                id: "scope",
                title: t("user-admin-table-scope"),
                render: renderTranslate("enum-scope-"),
                filterEnum: enumScopes,
              },
              { id: "active", title: "", render: renderBool() },
              {
                id: "id",
                title: "",
                render: renderButton(
                  t("admin-switch-context"),
                  handleUserSwitch,
                  "outline-dark",
                  "shuffle"
                ),
              },
              {
                id: "lastlogin",
                title: t("user-admin-table-lastlogin"),
                render: renderDate("lll"),
              },
            ]}
            allowEdit
            onEdit={(id) => {
              openEditModal(id);
            }}
          />
        </div>
      </div>
    </LayoutBasic>
  );
};

PageAdminUsers.propTypes = {};

export default PageAdminUsers;
