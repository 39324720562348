import React, { useContext, useEffect } from "react";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";
import { useLocation, useNavigate } from "react-router-dom";
import GoogleSignInButton from "../Components/GoogleSignInButton.js";
import Style from "./PageLogin.module.scss";
import { apiUrl } from "../configs/appConfig.js";

const PageLogin = () => {
  const { t } = useTranslation();
  const { storeLoginData } = useContext(UserContext);
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const openLoginDialog = () => {
    window.open(`${apiUrl}/login/google-popup`, "login-popup");
  };

  useEffect(() => {
    window.addEventListener("message", handlePostEvent, false);

    return () => {
      window.removeEventListener("message", handlePostEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostEvent = (event) => {
    if (event.origin === apiUrl && event.type === "message") {
      const { action, token, userinfo } = event.data;
      if (action === "login") {
        storeLoginData(token, JSON.parse(userinfo));

        // if on /login page, redir...
        if (currentLocation?.pathname === "/login") {
          navigate("/");
        }
      }
    }
  };

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("login-page-title")}</title>
      </Helmet>
      <div className={Style.wrapper}>
        <Alert variant="info">{t("login-page-info")}</Alert>
        <GoogleSignInButton onClick={openLoginDialog} />
      </div>
    </LayoutBasic>
  );
};

export default PageLogin;
