import fetch from "unfetch";
import { apiUrl } from "../configs/appConfig.js";

const buildRequestUrl = (url) => {
  return url.slice(0, 8) === "https://" || url.slice(0, 7) === "http://"
    ? url
    : `${apiUrl}${url}`;
};

const buildHeaders = (token) => {
  const basicHeaders = {
    "Content-Type": "application/json",
  };

  return token
    ? { ...basicHeaders, Authorization: `Bearer ${token}` }
    : basicHeaders;
};

export const swrFetcher = async (query, token) => {
  const headers = buildHeaders(token);
  const requestUrl = buildRequestUrl(query);

  const resp = await fetch(requestUrl, {
    headers,
  });
  return await resp.json();
};

export const swrFetcherRawJson = async (requestUrl) => {
  const resp = await fetch(requestUrl);
  return await resp.json();
};

export const swrFetcherRawText = async (requestUrl) => {
  const resp = await fetch(requestUrl);
  return await resp.text(); //.json();
};

export const postRequest = async (query, token, data) => {
  return new Promise((resolve, reject) => {
    const headers = buildHeaders(token);
    const requestUrl = buildRequestUrl(query);

    fetch(requestUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((resp) => {
        resolve(resp.json());
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteRequest = async (query, token, data) => {
  return new Promise((resolve, reject) => {
    const headers = buildHeaders(token);
    const requestUrl = buildRequestUrl(query);

    fetch(requestUrl, {
      method: "DELETE",
      headers,
      body: JSON.stringify(data),
    })
      .then((resp) => {
        resolve(resp.json());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
