import React from "react";
// import PropTypes from "prop-types";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import useOnlineStatus from "../hooks/useOnlineStatus.js";
// import UserContext from "../UserContext.js";
import {} from "react-bootstrap";
import SimpleTable from "../Components/SimpleTable.js";
import { openHlsPlayer } from "../Helpers/hlsPlayer.js";
import { renderAvatar } from "../Components/SimpleTableRender/basic.js";
import { renderLang } from "../Components/SimpleTableRender/lang.js";
import { renderOnAirStatus } from "../Components/SimpleTableRender/onAirStatus.js";

const PageCommenters = () => {
  const { t } = useTranslation();
  // const { userinfo } = useContext(UserContext);
  const isOnline = useOnlineStatus();

  const { data, isValidating, mutate } = useSWR("/getCommenters", null, {
    refreshInterval: 20000,
  });
  const commenters = data?.response;

  const enumOnair = [
    { id: true, title: t("onair") },
    { id: false, title: t("offair") },
  ];

  const handleIceStream = (url, row) => {
    openHlsPlayer(url, row.fullname, row.email, "https");
  };

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("commenters-page-title")}</title>
      </Helmet>

      <div>
        <h1>{t("commenters-page-title")}</h1>
      </div>

      <div>
        <SimpleTable
          idx="id"
          data={commenters}
          isLoading={isValidating}
          onRefresh={mutate}
          cols={[
            { id: "avatar", title: "", render: renderAvatar() },
            {
              id: "fullname",
              title: t("user-admin-table-name"),
              filterText: true,
            },
            {
              id: "email",
              title: t("user-admin-table-email"),
              filterText: true,
            },
            {
              id: "languages",
              title: t("ready-and-live-stream-lang"),
              render: renderLang(),
            },
            {
              id: "stream_ice_status",
              title: t("commenters-stream-ice"),
              render: renderOnAirStatus(
                handleIceStream,
                isOnline,
                "stream_ice"
              ),
              filterEnum: enumOnair,
            },
          ]}
        />
      </div>
    </LayoutBasic>
  );
};

PageCommenters.propTypes = {};

export default PageCommenters;
