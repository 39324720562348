import React, { useMemo, useState, useReducer, useEffect } from "react";
import useDebounce from "../Helpers/useDebounce.js";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  Button,
  ButtonGroup,
  InputGroup,
  Table,
  Pagination,
  Form,
  FormControl,
} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Style from "./SimpleTable.module.scss";
import { renderPlain } from "./SimpleTableRender/basic.js";

dayjs.extend(localizedFormat);

const SimpleTable = ({
  data,
  pageSize,
  isLoading,
  idx,
  cols,
  allowEdit,
  allowDelete,
  allowSelection,
  onEdit,
  onDelete,
  onSelectionUpdate,
  onRefresh,
  onFiltersChange,
  headerComponent,
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState([]); // [{col:"name",value:"test" }}]
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const filtersDebounced = useDebounce(filters, 250);
  const isLoadingDebounced = useDebounce(isLoading, 250);

  const filterData = (data, filters) => {
    const filteredData =
      filters &&
      filters.reduce((ret, filter) => {
        const idx = filter.col;
        const val = String(filter.value).toLowerCase();

        return ret?.filter((item) => {
          return String(item[idx]).toLowerCase().search(val) >= 0
            ? true
            : false;
        });
      }, data);

    return filteredData;
  };

  const dataFiltered = useMemo(() => {
    const allData = filterData(data, filtersDebounced);
    if (!allData) return null;

    const allDataSize = allData.length;
    const totalPages = Math.ceil(allDataSize / pageSize);
    const sliceStart = (page - 1) * pageSize;
    const pagedData = allData.slice(sliceStart, sliceStart + pageSize);

    setPageCount(totalPages);

    return pagedData;
  }, [data, filtersDebounced, page, pageSize]);

  const [selected, updateSelected] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return [...state, action.value];

      case "remove":
        return state.filter((item) => item !== action.value);

      case "remove-all":
        return [];

      case "add-all":
        return dataFiltered.reduce((ret, item) => [...ret, item[idx]], []);

      default:
        console.error("unsopported action type in reducer");
    }
  }, []);

  useEffect(() => {
    if (onSelectionUpdate) {
      onSelectionUpdate(selected);
    }
  }, [selected, onSelectionUpdate]);

  useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange(filters);
    }
  }, [filters, onFiltersChange]);

  const selectedCount = selected ? selected.length : 0;
  const totalCount = data ? data.length : 0;

  const handleEditClick = (idx, itemIdx) => {
    if (onEdit) {
      onEdit(idx, itemIdx);
    }
  };

  const handleDeleteClick = (idx, itemIdx) => {
    // todo: show confirm before calling delete cb
    if (onDelete) {
      onDelete(idx, itemIdx);
    }
  };

  const handleDataRefresh = () => {
    if (onRefresh) {
      //toast.info(t("table-data-refresh-requested"));

      toast.promise(onRefresh, {
        pending: t("table-data-refresh-requested"),
        success: t("table-data-refresh-success"),
        error: t("table-data-refresh-error"),
      });
    }
  };

  const hasSearch = useMemo(
    () =>
      cols.reduce(
        (ret, item) => (item.filterText || item.filterEnum ? true : ret),
        false
      ),
    [cols]
  );

  const handleFilterFieldUpdate = (col, value) => {
    const newFilters = filters.filter((i) => i.col !== col);
    newFilters.push({ col, value });

    setFilters(newFilters);
  };

  const handleFilterFieldsClear = () => {
    setFilters([]);
  };

  const handlePaginationPrev = () => {
    setPage((old) => (old > 1 ? old - 1 : 1));
  };

  const handlePaginationNext = () => {
    setPage((old) => (old >= pageCount ? old : old + 1));
  };

  return (
    <Table striped hover size="sm" className={Style.table}>
      <thead className={Style.tableHead}>
        <tr>
          <th
            colSpan={cols.length + 2}
            className={Style.tableHeaderComponentsWrap}
          >
            <div className={Style.tableHeaderComponentsFlex}>
              <div className={Style.tableHeaderComponents}>
                {headerComponent && headerComponent()}
              </div>

              <div className={Style.tablePagination}>
                {pageCount > 1 && (
                  <Pagination size="sm" style={{ marginBottom: 0 }}>
                    <Pagination.First onClick={() => setPage(1)} />
                    <Pagination.Prev onClick={handlePaginationPrev} />

                    {page > 1 && (
                      <Pagination.Item onClick={() => setPage(1)}>
                        1
                      </Pagination.Item>
                    )}

                    {page > 6 && <Pagination.Ellipsis />}

                    {[page - 4, page - 3, page - 2, page - 1].map((p) =>
                      p > 1 ? (
                        <Pagination.Item key={p} onClick={() => setPage(p)}>
                          {p}
                        </Pagination.Item>
                      ) : null
                    )}

                    <Pagination.Item active>{page}</Pagination.Item>

                    {[page + 1, page + 2, page + 3, page + 4].map((p) =>
                      p < pageCount ? (
                        <Pagination.Item key={p} onClick={() => setPage(p)}>
                          {p}
                        </Pagination.Item>
                      ) : null
                    )}

                    {page < pageCount - 5 && <Pagination.Ellipsis />}

                    {page !== pageCount && (
                      <Pagination.Item onClick={() => setPage(pageCount)}>
                        {pageCount}
                      </Pagination.Item>
                    )}

                    <Pagination.Next onClick={handlePaginationNext} />
                    <Pagination.Last onClick={() => setPage(pageCount)} />
                  </Pagination>
                )}
              </div>

              <div className={Style.tableHeaderActions}>
                <ButtonGroup size="sm">
                  {hasSearch && (
                    <Button
                      variant="outline-dark"
                      size="sm"
                      onClick={handleFilterFieldsClear}
                    >
                      <i className="fa-thin fa-filter-slash" />
                    </Button>
                  )}

                  <Button
                    variant="outline-dark"
                    title={t("table-data-loaded")}
                    style={{ width: "34px" }}
                    onClick={handleDataRefresh}
                    disabled={onRefresh ? false : true}
                  >
                    {isLoadingDebounced ? (
                      <i className="fa-regular fa-rotate fa-spin" />
                    ) : (
                      <i className="fa-regular fa-check text-success" />
                    )}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </th>
        </tr>

        <tr>
          {allowSelection && (
            <th>
              <div
                data-tip={`${t(
                  "table-selected"
                )}: ${selectedCount} / ${totalCount}`}
              >
                {selectedCount > 0 && selectedCount < totalCount && (
                  <i
                    className="fa-thin fa-square-ellipsis"
                    onClick={() => {
                      updateSelected({
                        type: "remove-all",
                      });
                    }}
                  />
                )}
                {selectedCount === 0 && (
                  <i
                    className="fa-thin fa-square"
                    onClick={() => {
                      updateSelected({
                        type: "add-all",
                      });
                    }}
                  />
                )}
                {selectedCount > 0 && selectedCount === totalCount && (
                  <i
                    className="fa-thin fa-square-check  text-success"
                    onClick={() => {
                      updateSelected({
                        type: "remove-all",
                      });
                    }}
                  />
                )}
              </div>
              <ReactTooltip effect="solid" delayHide={600} />
            </th>
          )}
          {cols.map((col, colIndex) => (
            <th
              key={`table-head-col-${col.id}-${colIndex}`}
              className={`${Style.tableHeadItem} ${
                hasSearch && Style.hasSearch
              }`}
            >
              <div>{col.title}</div>

              <div className={Style.tableHeadFilter}>
                {col.headerComponent && col.headerComponent()}

                {col.filterText && (
                  <InputGroup size="sm">
                    <InputGroup.Text>
                      <i className="fa-thin fa-magnifying-glass" />
                    </InputGroup.Text>
                    <FormControl
                      value={
                        filters.filter((i) => i.col === col.id)[0]?.value || ""
                      }
                      onChange={(e) => {
                        handleFilterFieldUpdate(col.id, e.target.value);
                      }}
                      style={{ maxWidth: "160px" }}
                    />
                  </InputGroup>
                )}

                {col.filterEnum && (
                  <InputGroup size="sm">
                    <InputGroup.Text>
                      <i className="fa-thin fa-magnifying-glass" />
                    </InputGroup.Text>
                    <Form.Select
                      size="sm"
                      required
                      value={
                        filters.filter((i) => i.col === col.id)[0]?.value || ""
                      }
                      onChange={(e) => {
                        handleFilterFieldUpdate(col.id, e.target.value);
                      }}
                      style={{ maxWidth: "160px" }}
                    >
                      <option value=""></option>
                      {col.filterEnum &&
                        col.filterEnum.map((item) => (
                          <option key={item.id} value={item.id}>
                            {t(item.title)}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                )}
              </div>
            </th>
          ))}
          <th className={Style.actions}></th>
        </tr>
      </thead>
      <tbody>
        {dataFiltered &&
          dataFiltered.map((item, itemIdx) => {
            const idxValue = item[idx];
            return (
              <tr
                key={`table-row-${itemIdx}-${idxValue}`}
                className={`${Style.row} table-data-row table-data-row-${idxValue}`}
              >
                {allowSelection && (
                  <td>
                    <Form.Check
                      type="checkbox"
                      label=""
                      name={idxValue}
                      checked={selected.includes(idxValue)}
                      onChange={(e) => {
                        updateSelected({
                          type: e.target.checked ? "add" : "remove",
                          value: idxValue,
                        });
                      }}
                    />
                  </td>
                )}

                {cols.map((col, colIndex) => {
                  const renderFn = col.render || renderPlain();
                  const value = item[col.id] || "";
                  return (
                    <td key={`table-body-col-${col.id}-${itemIdx}-${colIndex}`}>
                      {renderFn(value, item, idxValue)}
                    </td>
                  );
                })}

                <td className={Style.actions}>
                  {(allowDelete || allowEdit) && (
                    <ButtonGroup>
                      {allowEdit && (
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleEditClick(idxValue, itemIdx)}
                        >
                          <i className="fa-thin fa-pencil me-2" />
                          {t("edit")}
                        </Button>
                      )}
                      {allowDelete && (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteClick(idxValue, itemIdx)}
                        >
                          <i className="fa-thin fa-trash me-2" />
                          {t("delete")}
                        </Button>
                      )}
                    </ButtonGroup>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

SimpleTable.propTypes = {
  data: PropTypes.array,
  pageSize: PropTypes.number,
  isLoading: PropTypes.bool,
  idx: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  allowEdit: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowSelection: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSelectionUpdate: PropTypes.func,
  onRefresh: PropTypes.func,
  onFiltersChange: PropTypes.func,
  headerComponent: PropTypes.func,
};
SimpleTable.defaultProps = {
  pageSize: 50,
};

export default SimpleTable;
