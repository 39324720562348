import React, { useContext, useEffect } from "react";
// import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";
import { isArray } from "lodash";
// import useSWR from "swr";

const PageHome = () => {
  const { isLoggedIn, userinfo } = useContext(UserContext);
  const userScope = userinfo?.scope;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");

    if (isArray(userScope)) {
      if (userScope.includes("admin")) {
        navigate("/ready-and-live");
        return;
      }
      if (userScope.includes("sportnet")) {
        navigate("/ready-and-live");
        return;
      }
      if (userScope.includes("team-leader")) {
        navigate("/ready-and-live");
        return;
      }
      if (userScope.includes("commenter")) {
        navigate("/my-matches");
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, userScope]);

  return <PageHomeAdmin />;
};

const PageHomeAdmin = () => {
  const { t } = useTranslation();

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("default-page-title")}</title>
      </Helmet>

      <h1>{t("default-page-title")}</h1>
    </LayoutBasic>
  );
};

export default PageHome;
