import React from "react";
import PropTypes from "prop-types";
import Style from "./GoogleSignInButton.module.scss";

const GoogleSignInButton = ({ onClick }) => {
  return <div className={Style.button} onClick={onClick}></div>;
};

GoogleSignInButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default GoogleSignInButton;
