import React, { useState, useContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import useSWR from "swr";
import { postRequest, deleteRequest } from "../Helpers/fetcher.js";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import UserContext from "../UserContext.js";
import Style from "./PageMatchInput.module.scss";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import LanguageIcon from "../Components/LanguageIcon.js";
import SimpleTable from "../Components/SimpleTable.js";
import {
  renderDateMultiline,
  renderRowValue,
} from "../Components/SimpleTableRender/basic.js";
import { renderMatchCommenters } from "../Components/SimpleTableRender/renderMatchCommenters.js";

const PageMatchInputEditation = () => {
  const { t } = useTranslation();
  const { userinfo, token } = useContext(UserContext);
  const MySwal = withReactContent(Swal);
  const { matchIdToEdit } = useParams();
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState(false);
  const [editMatchDetail, setEditMatchDetail] = useState(null);
  const [editLanguages, setEditLanguages] = useState([]);
  const [editCommenters, setEditCommenters] = useReducer((state, action) => {
    //console.log("setEditCommenters", action);

    if (action.type === "clean") {
      return [];
    }

    if (action.type === "init") {
      if (!action.payload) return [];
      return action.payload.reduce(
        (ret, item) => [
          ...ret,
          {
            langId: item.language,
            commenterId: item.id,
            stateId: item.state || 0,
            directStream: item.directStream || null,
          },
        ],
        []
      );
    }

    if (action.type === "setStream") {
      let editLine = state.reduce(
        (ret, item) => (item.langId === action.payload.langId ? item : ret),
        { langId: action.payload.langId }
      );

      if (
        action.payload.directStream !== null &&
        action.payload.directStream !== undefined
      ) {
        editLine.directStream = action.payload.directStream;
        editLine.commenterId = "-1";
        editLine.stateId = 1;
      }

      return [
        ...state.filter((item) =>
          item.langId === action.payload.langId ? false : true
        ),
        editLine,
      ];
    }

    if (action.type === "update") {
      let editLine = state.reduce(
        (ret, item) => (item.langId === action.payload.langId ? item : ret),
        { langId: action.payload.langId }
      );

      if (
        action.payload.commenterId !== null &&
        action.payload.commenterId !== undefined
      ) {
        editLine.commenterId = action.payload.commenterId;
      }

      if (
        action.payload.stateId !== null &&
        action.payload.stateId !== undefined
      ) {
        editLine.stateId = action.payload.stateId;
      }

      return [
        ...state.filter((item) =>
          item.langId === action.payload.langId ? false : true
        ),
        editLine,
      ];
    }

    return state;
  }, []);

  //console.log({ editCommenters });

  const { data, isValidating, mutate } = useSWR(
    `/getSelectedMatches/${userinfo.id}`
  );
  const { data: dataEnumTournaments } = useSWR("/getEnum/tournamentTemplate");
  const { data: dataEnumMatchStates } = useSWR("/getEnum/matchStates");
  const { data: dataEnumSports } = useSWR("/getEnum/sports");
  //const { data: dataEnumLanguages } = useSWR("/getEnum/languages");

  const matchList = data?.response?.matches;
  const enumTournaments = dataEnumTournaments?.response?.enums;
  const enumMatchStates = dataEnumMatchStates?.response?.enums;
  const enumSports = dataEnumSports?.response?.enums;
  //const enumLanguages = dataEnumLanguages?.response?.enums;

  useEffect(() => {
    // open edit popup if there is an ?id param in the URL
    if (matchIdToEdit && matchList && !isValidating) {
      setTimeout(() => {
        openEditModal(matchIdToEdit);
        navigate(`/match-input/edit`);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchList, matchIdToEdit, isValidating]);

  const handleEditSave = () => {
    toast.promise(
      new Promise((resolve, reject) => {
        postRequest("/updateSelectedMatch", token, {
          matchId: editMatchDetail.id,
          commenters: editCommenters,
        })
          .then((resp) => {
            if (resp.error_code !== 200) {
              toast.error(t(`error-${resp.error}`));
              reject();
              return false;
            }

            hideEditModal();
            mutate()
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          })
          .catch((err) => {
            reject();
          });
      }),
      {
        pending: t("match-input-edit-saving"),
        success: t("match-input-edit-saved"),
        error: t("match-input-edit-error"),
      }
    );
  };

  const openEditModal = (matchId) => {
    if (!matchList || !matchId) return false;

    const matchDetail = matchList.reduce(
      (ret, match) => (match.id === matchId ? match : ret),
      null
    );

    /*
    const matchLanguages =
      matchDetail?.commenters &&
      matchDetail.commenters.reduce((ret, c) => [...ret, c.language], []);

    const userAndMatchLanguages = [
      ...new Set([...matchLanguages, ...userinfo.languages]),
    ];
    */
    const userAndMatchLanguages = [...new Set([...userinfo.languages])];

    resetFormState();
    setShowEditModal(true);
    setEditMatchDetail(matchDetail);
    setEditLanguages(userAndMatchLanguages);
    setEditCommenters({ type: "init", payload: matchDetail?.commenters });
  };

  const handleMatchDelete = (matchId) => {
    if (!matchList || !matchId) return false;

    const matchDetail = matchList.reduce(
      (ret, match) => (match.id === matchId ? match : ret),
      null
    );

    MySwal.fire({
      title: t("Please confirm delete operation"),
      html: `<div>${dayjs(matchDetail.timeStart).format("LL LT")} <br/> ${
        matchDetail.participantNames
      }</div>`,
      showCancelButton: true,
      confirmButtonText: t("confirm-delete"),
      cancelButtonText: t("cancel"),
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        toast.promise(
          async () => {
            await deleteRequest("/transferMatches", token, {
              ids: [parseInt(matchId)],
            });

            await mutate(); // reload data
          },
          {
            pending: t("match-input-edit-removing"),
            success: t("match-input-edit-removed"),
          }
        );
      }
    });
  };

  const hideEditModal = () => {
    setShowEditModal(false);
    resetFormState();
  };

  const resetFormState = () => {
    setEditMatchDetail(null);
    setEditCommenters({ type: "clean" });
  };

  return (
    <>
      <Helmet>
        <title>{t("match-input-page-title")}</title>
      </Helmet>

      <Modal
        show={showEditModal}
        onHide={hideEditModal}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("match-input-edit-item")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <Row>
              <Col md={6} xs={12}>
                <strong>{t("match-input-table-home-participant")}:</strong>{" "}
                {editMatchDetail?.homeParticipantName}
              </Col>
              <Col md={6} xs={12}>
                <strong>{t("match-input-table-away-participant")}:</strong>{" "}
                {editMatchDetail?.awayParticipantName}
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12}>
                <strong>{t("date")}:</strong>{" "}
                {dayjs(editMatchDetail?.timeStart).format("ll")}
              </Col>
              <Col md={6} xs={12}>
                <strong>{t("match-input-table-tournament")}:</strong>{" "}
                {editMatchDetail?.tournamentTemplateName}
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12}>
                <strong>{t("time")}:</strong>{" "}
                {dayjs(editMatchDetail?.timeStart).format("LT")}
              </Col>
              <Col md={6} xs={12}>
                <strong>{t("id")}:</strong> {editMatchDetail?.id}
              </Col>
            </Row>
          </section>

          <hr />

          <div className="title h5 mb-3">
            {t("match-input-edit-select-commenters")}:
          </div>

          <section>
            {editLanguages.map((lang) => (
              <EditLanguageRow
                key={`${lang}`}
                lang={lang}
                commenters={editCommenters}
                onCommenterUpdate={setEditCommenters}
              />
            ))}
          </section>

          <hr />

          <section>
            <Form.Group className="text-end">
              <Button variant="secondary" onClick={hideEditModal}>
                {t("cancel")}
              </Button>
              &nbsp;
              <Button variant="primary" onClick={handleEditSave}>
                {t("save")}
              </Button>
            </Form.Group>
          </section>
        </Modal.Body>
      </Modal>

      <div className={Style.wrapper}>
        <div className={Style.matchTable}>
          <SimpleTable
            data={matchList}
            isLoading={isValidating}
            onRefresh={mutate}
            idx="id"
            allowEdit
            onEdit={(matchId) => {
              openEditModal(matchId);
            }}
            allowDelete
            onDelete={(matchId) => {
              handleMatchDelete(matchId);
            }}
            cols={[
              {
                id: "timeStart",
                title: t("date"),
                render: renderDateMultiline(["LL", "LT"]),
              },
              {
                id: "participantNames",
                title: t("match-input-table-participants"),
                filterText: true,
              },
              {
                id: "sportId",
                title: t("match-input-table-sport"),
                render: renderRowValue("sportName"),
                filterEnum: enumSports,
              },
              {
                id: "tournamentTemplateId",
                title: t("match-input-table-tournament"),
                render: renderRowValue("tournamentTemplateName"),
                filterEnum: enumTournaments,
              },

              {
                id: "commenters",
                title: t("match-input-table-commenters"),
                render: renderMatchCommenters(enumMatchStates),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

PageMatchInputEditation.propTypes = {};

const EditLanguageRow = ({ lang, commenters, onCommenterUpdate }) => {
  const { t } = useTranslation();
  const { data: dataEnumCommenters } = useSWR(`/getEnum/commenters/${lang}`);
  const { data: dataEnumMatchStates } = useSWR("/getEnum/matchStates");
  const enumCommenters = dataEnumCommenters?.response?.enums;
  const enumMatchStates = dataEnumMatchStates?.response?.enums;

  const updateCommenterForLanguage = (e) => {
    onCommenterUpdate({
      type: "update",
      payload: {
        langId: lang,
        commenterId: e.target.value,
        stateId: e.target.value === "" ? "0" : "1",
      },
    });
  };

  const updateStreamForLanguage = (e) => {
    onCommenterUpdate({
      type: "setStream",
      payload: {
        langId: lang,
        directStream: e.target.value,
      },
    });
  };

  const updateStateForLanguage = (e) => {
    onCommenterUpdate({
      type: "update",
      payload: {
        langId: lang,
        commenterId: null,
        stateId: e.target.value,
      },
    });
  };

  const currentCommenter = commenters.reduce(
    (ret, item) => (item.langId === lang ? item.commenterId : ret),
    ""
  );

  const currentCommenterStream = commenters.reduce(
    (ret, item) => (item.langId === lang ? item.directStream : ret),
    ""
  );

  return (
    <div>
      <Form.Group
        as={Row}
        className="mb-3"
        controlId={`commenter-select-${lang}`}
      >
        <Form.Label column md={2} sm={4} xs={12}>
          <LanguageIcon langId={lang} withLabel />
        </Form.Label>

        <Col md={7} sm={4} xs={12}>
          <Form.Select
            value={currentCommenter}
            onChange={updateCommenterForLanguage}
          >
            <option value="">{t("none")}</option>

            {enumCommenters &&
              enumCommenters.map((commenter) => (
                <option key={commenter.id} value={commenter.id}>
                  {commenter.title}
                </option>
              ))}

            <option value="-1">{t("match-input-edit-direct-stream")}</option>
          </Form.Select>
          {currentCommenter === "-1" && (
            <div>
              <Form.Control
                type="text"
                value={currentCommenterStream}
                onChange={updateStreamForLanguage}
              />
            </div>
          )}
        </Col>

        <Col md={3} sm={4} xs={12}>
          <Form.Select
            value={commenters.reduce(
              (ret, item) => (item.langId === lang ? item.stateId : ret),
              ""
            )}
            onChange={updateStateForLanguage}
            disabled={commenters.reduce(
              (ret, item) =>
                item.langId === lang
                  ? item.commenterId !== ""
                    ? false
                    : true
                  : ret,
              true
            )}
          >
            {enumMatchStates &&
              enumMatchStates.map((state) => (
                <option
                  key={state.id}
                  value={state.id}
                  disabled={state.userSelectable === false}
                >
                  {t(state.title)}
                </option>
              ))}
          </Form.Select>
        </Col>
      </Form.Group>
    </div>
  );
};
EditLanguageRow.propTypes = {
  lang: PropTypes.string,
  commenters: PropTypes.array,
  onCommenterUpdate: PropTypes.func,
};

export default PageMatchInputEditation;
