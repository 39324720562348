import React, { Suspense, Fragment, useContext, useEffect } from "react";
import { createRoot } from "react-dom/client";
import PropTypes from "prop-types";
import { SWRConfig } from "swr";
import { swrFetcher } from "./Helpers/fetcher";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import UserContext, { UserContextStore } from "./UserContext.js";
import "./i18n.js";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
//import "dayjs/locale/cs";
import "dayjs/locale/en";
import "./index.scss";
import routes from "./configs/routes.json";
import Page404 from "./Pages/Page404.js";
import Page403 from "./Pages/Page403.js";
import PageLogin from "./Pages/PageLogin.js";
import imgLoading from "./assets/animations/loading.gif";

const getRouteComponent = (name) => {
  const RouteComponents = {
    PageHome: require("./Pages/PageHome.js").default,
    PageDebug: React.lazy(() => import("./Pages/PageDebug.js")),
    PageLogin: require("./Pages/PageLogin.js").default,

    PageReadyAndLive: require("./Pages/PageReadyAndLive.js").default,
    PageMatchInput: require("./Pages/PageMatchInput.js").default,
    PageHistory: require("./Pages/PageHistory.js").default,
    PageMyMatches: require("./Pages/PageMyMatches.js").default,
    PageMyEncoderConfig: require("./Pages/PageMyEncoderConfig.js").default,
    PageUserAdmin: require("./Pages/PageAdminUsers.js").default,
    PageCommenters: require("./Pages/PageCommenters.js").default,
    PageSystemStatus: require("./Pages/PageSystemStatus.js").default,
    PageAdminAuditLog: require("./Pages/PageAdminAuditLog.js").default,
  };

  const componentName = `${name}`;
  if (typeof RouteComponents[componentName] !== "undefined") {
    return RouteComponents[componentName];
  }

  return Page404;
};

const AuthorizedRoute = ({ loginScope, children }) => {
  const { isLoggedIn, userinfo } = useContext(UserContext);

  if (loginScope === "*" || loginScope === false) {
    return children;
  }

  if (isLoggedIn !== true) return <PageLogin />;

  const userScopes = userinfo?.scope || [];

  return userScopes.reduce(
    (ret, userScope) => (loginScope.includes(userScope) ? children : ret),
    <Page403 />
  );
};
AuthorizedRoute.propTypes = {
  loginScope: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  children: PropTypes.object.isRequired,
};
AuthorizedRoute.defaultProps = {
  loginScope: "*",
};

function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return children;
}
//const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n && i18n.language);
  const { token } = useContext(UserContext);

  return (
    <Fragment>
      <Helmet>
        <title>{t("default-page-title")}</title>
      </Helmet>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          fetcher: (q) => swrFetcher(q, token),
        }}
      >
        <ToastContainer
          position="top-right"
          newestOnTop={true}
          closeOnClick={true}
        />
        <ScrollToTop>
          <Suspense
            fallback={
              <section id="app">
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={imgLoading} alt={t("loading")} />
                  {t("loading")}
                </div>
              </section>
            }
          >
            <Routes>
              {routes &&
                routes.map((route) => {
                  const RouteComponent = getRouteComponent(route.component);
                  return (
                    <Route
                      key={`${route.path}-${route.component}`}
                      path={route.path}
                      exact={route.exact || false}
                      element={
                        <AuthorizedRoute loginScope={route.loginScope || false}>
                          <RouteComponent />
                        </AuthorizedRoute>
                      }
                    />
                  );
                })}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </SWRConfig>
    </Fragment>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <UserContextStore>
      <App />
    </UserContextStore>
  </BrowserRouter>
);
