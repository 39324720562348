import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Form } from "react-bootstrap";
import Style from "./FormScopeSelect.module.scss";

const FormScopeSelect = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(value || []);
  const { data } = useSWR("/getEnum/scopes");
  const enumScopes = data?.response?.enums;

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [selected, onChange]);

  const handleSelectChange = (e) => {
    const isChecked = e.target.checked;
    const scopeId = e.target.name;

    if (isChecked) {
      // add to selected
      setSelected([...selected, scopeId]);
    }

    if (!isChecked) {
      // remove from selected
      setSelected(selected.filter((i) => i !== scopeId));
    }
  };

  return (
    <div className={Style.wrapper}>
      {enumScopes &&
        enumScopes.map((scope) => (
          <div key={`checkbox-scope-${scope.id}`} className={Style.item}>
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                name={scope.id}
                id={`checkbox-scope-${scope.id}`}
                checked={selected.includes(scope.id)}
                onChange={handleSelectChange}
              />
              <Form.Check.Label
                className={Style.label}
                htmlFor={`checkbox-scope-${scope.id}`}
              >
                {t(scope.title)}
              </Form.Check.Label>
            </Form.Check>
          </div>
        ))}
    </div>
  );
};

FormScopeSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default FormScopeSelect;
