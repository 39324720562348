import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Styles from "./Page404.module.scss";

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("page-not-found")}</title>
      </Helmet>

      <h1 className={Styles.title}>
        <span>404</span> {t("page-not-found")}
      </h1>

      <div className={Styles.nav}>{t("page-not-found-desc")}</div>
    </LayoutBasic>
  );
};

export default Page404;
