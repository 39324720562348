import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: { translation: require("./localize/en.json") },
};

const fallbackLng = ["en"];
const availableLanguages = ["en"];

const onLanguageChange = (lng) => {
  window.language = lng;
};

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .on("languageChanged", onLanguageChange)
  .init({
    resources,
    fallbackLng,
    whitelist: availableLanguages,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: false,
    saveMissing: false,
  });

export default i18n;
