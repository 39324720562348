import React from "react";
import { Image } from "react-bootstrap";
import { t } from "i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Style from "../SimpleTable.module.scss";
import { Button, ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import { isArray } from "lodash";
import { copyTextToClipboard } from "../../Helpers/copyToClipboard.js";

export const renderPlain = () => {
  const cb = (value, _row, _idxValue) => {
    return <>{value}</>;
  };
  return cb;
};
renderPlain.propTypes = {};

export const renderRowValue = (rowId) => {
  const cb = (_value, row, _idxValue) => {
    return <>{row[rowId]}</>;
  };
  return cb;
};

export const renderAvatar = () => {
  const cb = (value, _row, _idxValue) => {
    return (
      <div className={Style.avatar}>
        <Image fluid roundedCircle src={value} />
      </div>
    );
  };
  return cb;
};

export const renderBool = () => {
  const cb = (value, _row, _idxValue) => {
    if (value === true || value === "1" || value === "true")
      return <i className="fa-regular fa-check-circle" />;
    return <i className="fa-regular fa-circle-xmark" />;
  };
  return cb;
};

export const renderDate = (format) => {
  dayjs.extend(relativeTime);
  const cb = (value, _row, _idxValue) => {
    if (format === "relative") {
      return <>{dayjs(value).fromNow()}</>;
    }

    return <>{dayjs(value).format(format || "llll")}</>;
  };
  return cb;
};

export const renderDateMultiline = (formats) => {
  dayjs.extend(relativeTime);
  const cb = (value, _row, _idxValue) => {
    return (
      <div>
        {formats.map((format, idx) => (
          <div key={idx}>
            {format === "relative"
              ? dayjs(value).fromNow()
              : dayjs(value).format(format || "llll")}
          </div>
        ))}
      </div>
    );
  };
  return cb;
};

export const renderTranslate = (prefix, fallbackText) => {
  const translate = (text, fallback) => {
    const translated = t(text);

    return text === translated && fallback ? fallback : translated;
  };

  const cb = (value, row, _idxValue) => {
    try {
      const valueArr = JSON.parse(value).reduce(
        (ret, item) => [...ret, t(`${prefix}${item}`)],
        []
      );
      return <>{valueArr.join(", ")}</>;
    } catch {
      return <>{translate(`${prefix}${value}`, row[fallbackText])}</>;
    }
  };
  return cb;
};

export const renderButton = (text, onClick, variant = "dark", icon) => {
  const cb = (value, _row, idxValue) => {
    return (
      <Button
        size="sm"
        variant={variant}
        onClick={() => {
          onClick(value, idxValue);
        }}
      >
        {icon && <i className={`fa-thin fa-${icon} me-2`} />}
        {text}
      </Button>
    );
  };
  return cb;
};

export const renderMoreValueButton = (actions) => {
  const cb = (val, row, idxValue) => {
    const MySwal = withReactContent(Swal);
    const value = actions.transform
      ? actions.transform(val, row, idxValue)
      : val;

    const handleCopy = () => {
      copyTextToClipboard(value)
        .then(() => {
          toast.info(t("clipboard-copy-done"));
        })
        .catch(() => {});
    };

    const handlePopup = () => {
      MySwal.fire({
        title: t("log-detail"),
        html: `<div style="text-align:left"><pre>${JSON.stringify(
          JSON.parse(value),
          null,
          2
        )}</pre></div>`,
      });
    };

    return (
      <ButtonGroup aria-label="Basic example">
        {actions.copy && (
          <Button size="sm" variant="outline-dark" onClick={handleCopy}>
            <i className={`fa-thin fa-copy`} />
          </Button>
        )}

        {actions.popup && (
          <Button size="sm" variant="outline-dark" onClick={handlePopup}>
            <i className={`fa-thin fa-maximize`} />
          </Button>
        )}
      </ButtonGroup>
    );
  };
  return cb;
};
