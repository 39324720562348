export const saveTextAsFile = (content, contentType, fileName) => {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
};

export const loadTextFromFile = async () => {
  return new Promise((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";

    input.addEventListener("change", (e) => {
      const file = e.target.files[0];
      if (!file) return false;

      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        if (contents) {
          resolve(contents);
        } else {
          reject("load-from-file-no-data");
        }
      };
      reader.readAsText(file);
    });

    input.click();
  });
};
