import React from "react";
//import PropTypes from "prop-types";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import Style from "./PageAdminUsers.module.scss";
import SimpleTable from "../Components/SimpleTable.js";
import {
  renderDateMultiline,
  renderMoreValueButton,
} from "../Components/SimpleTableRender/basic.js";

const PageAdminUsers = () => {
  const { t } = useTranslation();
  const { data, isValidating, mutate } = useSWR("/getAuditLog");
  const auditLog = data?.response;

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("admin-users-page-title")}</title>
      </Helmet>

      <div className={Style.wrapper}>
        <div className={Style.userTable}>
          <SimpleTable
            data={auditLog}
            isLoading={isValidating}
            onRefresh={mutate}
            idx="id"
            cols={[
              {
                id: "time",
                title: t("date"),
                render: renderDateMultiline(["LL", "LT"]),
              },
              {
                id: "fullname",
                title: t("user-admin-table-name"),
                filterText: true,
              },
              {
                id: "email",
                title: t("user-admin-table-email"),
                filterText: true,
              },

              { id: "action", title: t("log-action"), filterText: true },
              { id: "ip", title: t("log-ip"), filterText: true },
              {
                id: "details",
                title: t("log-detail"),
                render: renderMoreValueButton({ copy: true, popup: true }),
              },
            ]}
          />
        </div>
      </div>
    </LayoutBasic>
  );
};

PageAdminUsers.propTypes = {};

export default PageAdminUsers;
