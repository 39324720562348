import React, { useContext } from "react";
// import PropTypes from "prop-types";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";
//import Style from "./PageXXX.module.scss";
import SimpleTable from "../Components/SimpleTable.js";
import {
  renderDateMultiline,
  renderRowValue,
} from "../Components/SimpleTableRender/basic.js";
import { renderLang } from "../Components/SimpleTableRender/lang.js";
// import {} from "react-bootstrap";

const PageHistory = () => {
  const { t } = useTranslation();
  const { userinfo } = useContext(UserContext);

  const { data, isValidating, mutate } = useSWR(
    `/getMyMatches/${
      userinfo.scope.includes("admin") ? "" : userinfo.id
    }?hist=1`
  );
  const { data: dataEnumTournaments } = useSWR("/getEnum/tournamentTemplate");
  const { data: dataEnumSports } = useSWR("/getEnum/sports");

  const matchList = data?.response?.matches;
  const enumTournaments = dataEnumTournaments?.response?.enums;
  const enumSports = dataEnumSports?.response?.enums;

  let tblColumns = [
    {
      id: "timeStart",
      title: t("date"),
      render: renderDateMultiline(["LL", "LT"]),
    },
    {
      id: "participantNames",
      title: t("match-input-table-participants"),
      filterText: true,
    },
    {
      id: "sportId",
      title: t("match-input-table-sport"),
      render: renderRowValue("sportName"),
      filterEnum: enumSports,
    },
    {
      id: "tournamentTemplateId",
      title: t("match-input-table-tournament"),
      render: renderRowValue("tournamentTemplateName"),
      filterEnum: enumTournaments,
    },
    {
      id: "language",
      title: t("match-input-table-lang"),
      render: renderLang(),
    },
  ];

  if (data?.response?.isAdmin || data?.response?.isTeamLeader) {
    tblColumns.push({
      id: "commenterName",
      title: t("ready-and-live-stream-commenter"),
    });
  }

  /*
  if (data?.response?.isAdmin) {
    tblColumns.push({
      id: "listeners",
      title: t("stats"),
    });
  }
  */

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("page-history-title")}</title>
      </Helmet>
      <div className={""}>
        <h1>{t("page-history-title")}</h1>
        <SimpleTable
          data={matchList}
          isLoading={isValidating}
          onRefresh={mutate}
          idx="id"
          cols={tblColumns}
        />
      </div>
    </LayoutBasic>
  );
};

PageHistory.propTypes = {};

export default PageHistory;
