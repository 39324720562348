import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSessionStorage } from "react-use-storage";
import { useNavigate } from "react-router-dom";
import { isObject } from "lodash";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { postRequest } from "./Helpers/fetcher.js";

const UserContext = React.createContext({});

export const UserContextStore = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const isTokenValid = (token) => {
    if (!token) return false;
    try {
      const expire = JSON.parse(atob(token.split(".")[1])).exp * 1000;
      const dtExpire = dayjs(expire);
      const dtNow = dayjs();

      if (dtNow.isAfter(dtExpire)) return false;

      // if valid, return how long till expire in seconds...
      return dtExpire.diff(dtNow, "seconds");
    } catch {
      return false;
    }
  };

  const defaultUserContext = { isLoggedIn: false, token: "", userinfo: {} };
  const [userStored, updateUserStored] = useSessionStorage(
    "user-session",
    defaultUserContext
  );
  const [user, setUser] = useState(
    isObject(userStored) && isTokenValid(userStored?.token)
      ? userStored
      : defaultUserContext
  );

  const storeLoginData = (token, userinfo) => {
    // validate token !!!  and userinfo

    const userObject = {
      ...defaultUserContext,
      isLoggedIn: true,
      token,
      userinfo,
    };

    setUser(userObject);
    updateUserStored(userObject);
  };

  const userLogout = () => {
    const userObject = {
      ...defaultUserContext,
      isLoggedIn: false,
    };

    setUser(userObject);
    updateUserStored(userObject);
    navigate("/");
  };

  const tokenCheck = () => {
    const token = user?.token;
    if (!token) return false;

    const expire = isTokenValid(token);
    if (!expire) {
      // token expired!
      toast.warning(t(`token-expired`));
      userLogout();
      return false;
    }

    if (expire < 299) {
      // try to refresh the token from API...
      postRequest("/login/restore", token, { token })
        .then((resp) => {
          const token = resp?.response?.token;
          const userinfo = resp?.response?.userinfo;

          if (token) {
            storeLoginData(token, userinfo);
            toast.info(t(`token-restored`));
          }
        })
        .catch(() => {
          // ignore...
        });
    }
  };

  // check if token is valid
  useEffect(() => {
    tokenCheck();

    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.info(":: DEBUG :: Token", user?.token);
    }

    const timer = setInterval(() => {
      tokenCheck();
    }, 150 * 1000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn: user.isLoggedIn,
        token: user.token,
        userinfo: user.userinfo,
        storeLoginData,
        userLogout,
        menuCollapsed,
        setMenuCollapsed,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export default UserContext;

UserContextStore.propTypes = {
  children: PropTypes.object.isRequired,
};
