import React from "react";
import { t } from "i18next";
import { isArray } from "lodash";
import LanguageIcon from "../LanguageIcon.js";
import { Badge } from "react-bootstrap";

export const renderMatchCommenters = (enumMatchStates) => {
  const cb = (value, _row, idxValue) => {
    if (!isArray(value) || value.length < 1) return <div>-</div>;

    const keyId = `matchCommenters-${idxValue}-${value}`;

    return (
      <div key={keyId} style={{ fontSize: "0.9em" }}>
        {value.map((commenter, idx) => {
          const matchState = enumMatchStates?.reduce(
            (ret, item) =>
              item.id === parseInt(commenter.state)
                ? { title: item.title, variant: item.variant }
                : ret,
            { title: "", variant: "" } // outline-dark
          );

          return (
            <div key={`${idx}-${commenter.language}-${commenter.id}`}>
              <LanguageIcon langId={commenter.language} />
              {commenter.fullname}
              <Badge
                pill
                bg={matchState?.variant}
                style={{ marginLeft: "5px" }}
              >
                {t(matchState?.title)}
              </Badge>
            </div>
          );
        })}
      </div>
    );
  };
  return cb;
};
