const hlsPlayerLink = (playlist, title, subtitle, type) => {
  return `https://hls.spectado.com/?stream=${encodeURIComponent(
    playlist
  )}&type=${type ?? "hls"}&title=${encodeURIComponent(
    title
  )}&subtitle=${encodeURIComponent(subtitle)}`;
};

const openHlsPlayer = (playlist, title, subtitle, type) => {
  const link = hlsPlayerLink(playlist, title, subtitle, type);

  window.open(link, "spectado-hls-player", "height=100, width=400");
};

const hlsPlayer = { open: openHlsPlayer, link: hlsPlayerLink };

export { hlsPlayerLink, openHlsPlayer };
export default hlsPlayer;
