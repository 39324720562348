import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { Button } from "react-bootstrap";
import Styles from "./Page404.module.scss";

const Page403 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("page-access-unauthorized")}</title>
      </Helmet>
      <section className={Styles.wrap}>
        <h1 className={Styles.title}>{t("page-access-unauthorized")}</h1>

        <div className="">{t("page-access-unauthorized-desc")}</div>
      </section>

      <section className="mt-5">
        <Button
          variant="primary"
          className="me-3"
          onClick={() => {
            navigate("/");
          }}
        >
          {t("page-access-unauthorized-cta-home")}
        </Button>

        <Button
          variant="secondary"
          onClick={() => {
            navigate("/login");
          }}
        >
          {t("page-access-unauthorized-cta-login")}
        </Button>
      </section>
    </LayoutBasic>
  );
};

export default Page403;
