import React, { useContext } from "react";
import PropTypes from "prop-types";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";
import { swrFetcher } from "../Helpers/fetcher.js";
import { saveTextAsFile } from "../Helpers/fileSaveLoad.js";
//import Style from "./PageMyEncoderConfig.module.scss";
import RenderMd from "../Components/RenderMd.js";
import { Button, Row, Col, Card, FloatingLabel, Form } from "react-bootstrap";

const PageMyEncoderConfig = () => {
  const { t } = useTranslation();
  const { userinfo, token } = useContext(UserContext);

  const { data } = useSWR(`/getCommenterConfig/${userinfo.id}`);
  const encoderConfig = data?.response;

  const handleConfigDownload = async () => {
    swrFetcher(`/getCommenterConfig/${userinfo.id}/download`, token)
      .then((resp) => {
        const config = JSON.stringify(resp.response, null, 2);
        saveTextAsFile(config, "application/octet-stream", "APRO.rbroadcast");
      })
      .catch();
  };

  const ConfigField = ({ field }) => {
    const { t } = useTranslation();

    return (
      <FloatingLabel
        controlId={`encoder-config-${field}`}
        label={t(`encoder-config-${field}`)}
        className="mb-3"
      >
        <Form.Control
          type="text"
          value={encoderConfig ? encoderConfig[field] : "..."}
          readOnly
        />
      </FloatingLabel>
    );
  };
  ConfigField.propTypes = {
    field: PropTypes.string.isRequired,
  };

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("my-encoder-config")}</title>
      </Helmet>

      <div className="">
        <h1>{t("my-encoder-config")}</h1>

        <Row>
          <Col md={6} sm={12}>
            <RenderMd lang="en" file="commenter-help" />
          </Col>

          <Col md={6} sm={12}>
            <Card>
              <Card.Header className="h5">
                {t("my-encoder-config-params")}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={8} sm={12}>
                    <ConfigField field="server" />
                  </Col>
                  <Col md={4} sm={12}>
                    <ConfigField field="port" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12} sm={12}>
                    <ConfigField field="mount" />
                  </Col>
                </Row>

                <Row>
                  <Col md={8} sm={12}>
                    <ConfigField field="password" />
                  </Col>
                  <Col md={4} sm={12}>
                    <ConfigField field="username" />
                  </Col>
                </Row>

                <Button variant="primary" onClick={handleConfigDownload}>
                  <i className="fa-thin fa-download me-2" />
                  {t("my-encoder-config-download")}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </LayoutBasic>
  );
};

export default PageMyEncoderConfig;
