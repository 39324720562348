import React from "react";
// import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import numeral from "numeral";
import dayjs from "dayjs";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Helmet from "react-helmet";
import useSWR from "swr";
import { swrFetcherRawText } from "../Helpers/fetcher.js";
import { useTranslation } from "react-i18next";
// import UserContext from "../UserContext.js";
import { Card } from "react-bootstrap";
//import NetdataChart from "../Components/NetdataChart.js";
import Style from "./PageSystemStatus.module.scss";
import StyleMD from "../Components/RenderMd.module.scss";
import changelogUiUrl from "../CHANGES.md";

const PageSystemStatus = () => {
  const { t } = useTranslation();
  // const { userinfo } = useContext(UserContext);

  const { data: changelogUi } = useSWR(changelogUiUrl, (url) =>
    swrFetcherRawText(url).then((resp) => resp)
  );

  const { data: changelogApi } = useSWR("/changes");

  const { data, isValidating } = useSWR("/getSystemStatus", null, {
    refreshInterval: 15000,
  });
  const status = data?.response;

  return (
    <LayoutBasic>
      <Helmet>
        <title>{t("system-status-page-title")}</title>
      </Helmet>
      <div className={Style.wrapper}>
        <div className={Style.titleLine}>
          <div>
            <h1>{t("system-status-page-title")}</h1>
          </div>
          <div>
            {isValidating && <i className="fa-thin fa-spinner-third fa-spin" />}
            &nbsp;
            {dayjs(data?.generated).format("ll, LTS")}
          </div>
        </div>

        <section className={Style.servers}>
          <div className={Style.serverBlock}>
            <Card className={`${Style.serverItem} bg-info`}>
              <Card.Body>
                <Card.Title>{t("system-status-commenters-online")}</Card.Title>
                <Card.Text>{status?.master?.sources}</Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className={Style.connectionBlock}>
            <i className="fa fa-down" />
          </div>

          <div className={Style.serverBlock}>
            <Card
              className={`${Style.serverItem} ${
                status?.master?.sources >= 0 ? "bg-success" : "bg-danger"
              }`}
            >
              <Card.Body>
                <Card.Title>{status?.master?.server}</Card.Title>
                <Card.Text>
                  {status?.master?.sources >= 0 ? "ONLINE" : "offline"}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className={Style.connectionBlock}>
            <i className="fa fa-down" />
          </div>

          <div className={Style.serverBlock}>
            {status?.edge?.map((item) => (
              <Card
                key={item.server}
                className={`${Style.serverItem} ${
                  item.load >= 0 ? "bg-success" : "bg-danger"
                }`}
              >
                <Card.Body>
                  <Card.Title>{item.server}</Card.Title>
                  <Card.Text>{item.load >= 0 ? `ONLINE` : "offline"}</Card.Text>
                  <div className={Style.serverInfo}>
                    <div>
                      <i className="fa-regular fa-users" />{" "}
                      {numeral(item.listeners).format("0,0")}
                    </div>

                    <div>
                      <i className="fa-regular fa-gauge-circle-bolt" />{" "}
                      {numeral(item.load).format("0.0")} %
                    </div>

                    <div>v {item.version}</div>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>

          <div className={Style.connectionBlock}>
            <i className="fa fa-plus" />
          </div>

          <div className={Style.serverBlock}>
            {status?.edgeIcecast?.map((item) => (
              <Card
                key={item.server}
                className={`${Style.serverItem} ${
                  item.load >= 0 ? "bg-success" : "bg-danger"
                }`}
              >
                <Card.Body>
                  <Card.Title>{item.server}</Card.Title>
                  <Card.Text>{item.load >= 0 ? `ONLINE` : "offline"}</Card.Text>
                  <div className={Style.serverInfo}>
                    <div>
                      <i className="fa-regular fa-users" />{" "}
                      {numeral(item.listeners).format("0,0")}
                    </div>

                    <div>
                      <i className="fa-regular fa-gauge-circle-bolt" />{" "}
                      {numeral(item.load).format("0.0")} %
                    </div>

                    <div>{item.version}</div>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        </section>

        <section className={Style.chengelogs}>
          <div className={Style.changelog}>
            <ReactMarkdown className={StyleMD.content}>
              {changelogUi}
            </ReactMarkdown>
          </div>
          <div className={Style.changelog}>
            <ReactMarkdown className={StyleMD.content}>
              {changelogApi?.response?.api}
            </ReactMarkdown>
          </div>
        </section>
      </div>
    </LayoutBasic>
  );
};

export default PageSystemStatus;
